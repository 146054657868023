import React from "react"
import ReviewLeft from "./Blocks/ReviewLeft"
import ReviewRight from "./Blocks/ReviewRight"

import benxoaImg from "../images/benxoa.jpg"
import brianImg from "../images/brian.jpg"

export default function Reviews() {
    return (
        <section className="py-10 bg-gray-100 md:py-20">
            <div className="container">
                <p className="text-3xl font-bold text-center text-gray-800">
                    Love for Antsle in the Wild
                </p>
                <div className="max-w-4xl py-10 mx-auto space-y-10">
                    <ReviewLeft
                        name="Ben Mauch"
                        position="Team Lead at TrustedSec"
                        img={benxoaImg}
                    >
                        The Antsles worked great. Everything was set up ahead of
                        the class and if a student needed a Windows or Ubuntu VM
                        we would just spin one up in seconds...
                    </ReviewLeft>
                    <ReviewRight
                        name="Brian Magrogan"
                        position="Sr. Systems Engineer at Verrex"
                        img={brianImg}
                    >
                        Antsle is extremely easy to setup and use. The templates
                        that are already in place make deployment very easy.
                        Once you have customized them to you it is a breeze to
                        duplicate [virtual servers] for whatever you need. The
                        interface is intuitive and easy to navigate around.
                    </ReviewRight>
                </div>
            </div>
        </section>
    )
}
